/*general css*/
body {
  direction: rtl;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.container {
  padding: 40px 30px
}

.TextField {
  /*   margin-bottom: 25px !important; */
}

.btn {
  margin-bottom: 15px !important;
  padding: 17px !important;
}

.align--left input {
  direction: ltr;
  padding-left: 20px
}

.align--center input {
  text-align: center
}

.sort--price>button {
  transition: background .4s;
}

.sort--price .Mui-selected {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

/*Home Styles*/
.home #root>.MuiBox-root {
  padding-top: 0;
}

.Home .logo--wrap {
  text-align: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  margin-bottom: 15px;
  padding: 23px 0;
  min-height: 43px;
}

.Home .date--wrap {
  display: flex;
  justify-content: space-between;
}

.Home .top--section {
  background: linear-gradient(180deg, #003275 7.75%, #426BA2 39.11%);
}

/*Loading*/
.loading--wrap {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000ad;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  z-index: 99999;
  transition: all .4s
}

.loading--wrap.show--loading {
  opacity: 1;
  visibility: visible
}

/*BackButton*/
.BackButton {
  border-bottom: solid 1px #eee;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  /* margin-bottom: 40px; */
  position: fixed;
  background: #fff;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
}

.BackButton span {
  color: #838383;
  margin-right: 20px;
  display: inline-block;
}

.BackButton button {
  all: unset;
  cursor: pointer;
}

.BackButton button svg {
  color: #838383
}

/*Bottom Navigation*/

.bottom--navigation .home svg {
  background: #1976d2;
  /*   width:41px;
  height:41px;*/
  padding: 19px;
  color: #fff !important;
  border-radius: 100%;
  position: relative;
  top: -5px;
  margin-top: -40px
}

.bottom--navigation .MuiBottomNavigationAction-root:before {
  content: '';
  width: 80%;
  height: 10px;
  background-color: #1976d2;
  position: absolute;
  bottom: -10px;
  left: 10%;
  border-radius: 30px;
  transition: all .4s
}

/* .bottom--navigation .MuiBottomNavigationAction-root.Mui-selected:before{
  bottom:-5px;
}*/
.bottom--navigation .MuiBottomNavigationAction-root.active:before {
  bottom: -5px;
}

.bottom--navigation .MuiBottomNavigationAction-root.active svg {
  color: #1976d2
}

/*Exchange Template*/
.exchange--wrap {
  background: #fff
}

.exchange--header {
  margin-bottom: 40px
}

.exchange--items .chat--icon svg {
  margin: 0 0 0 10px;
  background: #FFFFFF;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(66, 107, 162, 0.1), 10px 10px 20px rgba(66, 107, 162, 0.25);
  border-radius: 8px;
  padding: 7px;
  fill: #D3D3D3
}

.user--ofer--content svg {
  background: #FFFFFF;
  box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(66, 107, 162, 0.1), 10px 10px 20px rgba(66, 107, 162, 0.25);
  border-radius: 8px;
  padding: 7px;
  fill: #D3D3D3
}

.exchange--items.user--offer--items .chat--icon svg {
  box-shadow: none;
  background: none;
  fill: #838383;
}

.exchange--items .value svg {
  position: relative;
  top: 4px;
  padding-right: 10px
}

.exchange--items>div {
  margin-bottom: 30px
}

.special--items>div {
  margin-bottom: 0px;
  padding: 8px 10px;
}

.user--offer--items>div {
  margin-bottom: 0px;
  padding: 8px 10px;
}

.special--items>div:nth-child(even) {
  background: #F4F1E7;
  border-top: solid 1px #DDD8B0;
  border-bottom: solid 1px #DDD8B0;
}

/*chat*/
.chat~.bottom--navigation {
  display: none
}

/* table styles special buy price */
.table--head>tr>th {
  padding: 8px 16px;
  font-weight: bold;
}

.table--body>tr:nth-of-type(even) {
  background-color: #F5F5F5;
}

.table--body>tr>td:nth-of-type(2) {
  border-right: 1px solid;
  width: 55%;
}


/*message detail*/
.message-detail #root>.MuiBox-root {
  padding-bottom: 0;
}


/*IOS Helper*/
.iosHelp video {
  width:100%;
}
.iosHelp .helping {
  text-align: right;
  list-style: none;
  padding: 0;
}

/*sweet alert button*/
.swal2-popup button {
  /* font-size: 1.6rem !important; */
  font-family: inherit;
}


/*notofication box*/
.notofication--box {
  border: solid 1px #AAAAAA;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 7px;
  max-height: 74px;
  margin-bottom: 11px;
}

.notofication--box.not--seen {
  border-color: #838383
}

.notofication--box span {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-right: 12px
}

.notofication--box.not--seen span {
  color: #949494;
}


.notofication--box img {
  border-radius: 5px;
  max-width: 84px;
  max-height: 58px;
}

/*profile*/
.Profile span {
  font-weight:bold
}