@font-face {
  font-family: 'YekanBakh-Light';
  src: local('YekanBakh-Light'), url('../public/fonts/YekanBakh-Light.ttf') format('truetype');
}
/* @font-face {
  font-family:'YekanBakh';
  src: url('../fonts/YekanBakh/YekanBakh-Light.eot');
  src: url('../fonts/YekanBakh/YekanBakh-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/YekanBakh/YekanBakh-Light.woff') format('woff'),
      url('../fonts/YekanBakh/YekanBakh-Light.ttf') format('truetype'),
      url('../fonts/YekanBakh/YekanBakh-Light.svg#YekanBakh-Thin') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
} */


body {
  margin: 0;
  font-family: 'YekanBakh-Light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}